import React, { useState } from 'react';
import './module.portfolio.scss';
import { motion } from 'framer-motion';
import { portfolioArray } from '../utils/helpers';
import { Link } from 'react-router-dom';

export const Portfolio = () => {
  const [activePortfolioName, setActivePortfolioName] =
    useState<string>('Literats');
  const activePortfolio = portfolioArray.find(
    (e) => e.title === activePortfolioName
  );

  return (
    <motion.div
      initial={{ opacity: 0, display: 'none' }}
      transition={{
        duration: 0,
        ease: 'easeOut',
        delay: 1,
      }}
      animate={{ opacity: 1, display: 'flex' }}
      exit={{ opacity: 0, transition: { delay: 0, duration: 0.5 } }}
      className="root-portfolio"
    >
      <div className="container-portfolio">
        <ul className="portfolio-list">
          {portfolioArray.map((item, index) => (
            <motion.li
              initial={{
                translateY: -1000,
                translateX: 0,
                rotateZ: 0,
              }}
              animate={{
                translateY: 0,
                translateX: index % 2 === 0 ? 5 : -5,
                rotateZ: index % 2 === 0 ? 3 : -3,
              }}
              transition={{
                delay: 1 + index * 0.2,
                duration: 0.8,
                type: 'twin',
                stiffness: 100,
                damping: 10,
              }}
              key={index}
              onClick={() => {
                setActivePortfolioName(item.title);
              }}
              style={
                index % 2 === 0
                  ? { transform: `translateX( 5%) rotateZ(3deg)` }
                  : { transform: `translateX(-5%) rotateZ(-3deg)` }
              }
              className={
                activePortfolioName === item.title
                  ? 'portfolio-item-active'
                  : 'portfolio-item '
              }
            >
              {item.title.toUpperCase()}
            </motion.li>
          ))}
        </ul>
        <motion.div
          initial={{ y: -1000 }}
          animate={{ y: 0 }}
          transition={{
            delay: 3,
            duration: 0.8,
            type: 'spring',
            stiffness: 100,
            damping: 10,
          }}
          className="project-details"
        >
          <div className="project-details-section">
            <p className="subtitle-project">Name</p>
            <p className="p-text-title">{activePortfolio?.title}</p>
          </div>
          <div className="project-details-section">
            <p className="subtitle-project">Description</p>
            <p className="p-description-text">{activePortfolio?.description}</p>
          </div>

          <div className="project-details-section">
            <p className="subtitle-project">Tech Stack</p>
            <div className="techstack-wrapper">
              {activePortfolio?.technology.map((e) => (
                <p className="project-techstack-text "> # {e}</p>
              ))}
            </div>
          </div>

          <div className="button-wrapper">
            <Link to={activePortfolio!.link} target='blank' className="northern-lights-button">Check it out</Link>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

import React from 'react';
import { motion } from 'framer-motion';
import './about.scss';
import { text, text1 } from '../utils/helpers';

export const AboutMe = () => {
  return (
    <motion.div
      transition={{
        duration: 1,
        ease: 'easeOut',
        delay: 1,
      }}
      animate={{ opacity: 1, display: 'flex' }}
      initial={{ display: 'none', opacity: 0 }}
      exit={{ opacity: 0, transition: { delay: 0, duration: 0.5 } }}
      className="about-root"
    >
      <div className="conent">
        <div className="image-wrapper">
          <img alt="Jakbu Basinski" src="./images/AboutMe1.png" />
        </div>

        <div className="textContent">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              ease: 'easeOut',
              delay: 1.5,
            }}
            className="text-aboutMe-wrapper"
          >
            <h1 className="text-h1">Hi, my name is Kuba.</h1>
            <p className="text-p">{text}</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              ease: 'easeOut',
              delay: 2,
            }}
            className="text-aboutMe-wrapper1"
          >
            <h1 className="text-h1">In life,</h1>
            <p className="text-p">{text1}</p>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

//@ts-check
//@ts-ignore

import './module.navbar.scss';
import './module.navbar.scss';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export function Navbar() {
  const [NavisOpen, setIsOpen] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1200);
  const handleResize = () => {
    const wideScreen = window.innerWidth > 1200;
    setIsWideScreen(wideScreen);

    if (wideScreen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 1,
          ease: 'easeOut',
          // delay: 2.5,
        }}
        className="navbar-root"
      >
        {(NavisOpen || isWideScreen) && (
          <nav className="nav-menu">
            <ul>
              <li className="bumb">
                <NavLink
                  to="/"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  About Me
                </NavLink>
              </li>
              <li className="bumb">
                <NavLink
                  to="/technologies"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Technologies
                </NavLink>
              </li>
              <li className="bumb">
                <NavLink
                  to="/portfolio"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Portfolio
                </NavLink>
              </li>
              <li className="bumb">
                <NavLink
                  to="/contact"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Contact
                </NavLink>
              </li>
              <KeyboardDoubleArrowUpIcon
                onClick={() => {
                  setIsOpen(false);
                }}
                sx={{
                  display: 'none',
                  '@media (max-width: 1200px)': {
                    display: 'flex',
                    margin: 'auto',
                    fontSize: '60px',
                    border: 'solid #52c19c 1px',
                    color: '#52c19c',
                    borderRadius: '5px',

                    '&: hover': {
                      color: 'rgba(0, 255, 191, 0.8)',
                      borderColor: 'rgba(0, 255, 191, 0.8)',
                      cursor: 'pointer',
                    },
                  },
                }}
              />
            </ul>
          </nav>
        )}

        {(!NavisOpen || isWideScreen) && (
          <KeyboardDoubleArrowDownIcon
            onClick={() => {
              setIsOpen(true);
            }}
            sx={{
              display: 'none',
              '@media (max-width: 1200px)': {
                position: 'absolute',
                top: '15px',
                left: '10px',
                display: 'flex',
                margin: 'auto',
                fontSize: '40px',
                border: 'solid #52c19c 1px',
                color: '#52c19c',
                borderRadius: '5px',

                '&: hover': {
                  color: 'rgba(0, 255, 191, 0.8)',
                  borderColor: 'rgba(0, 255, 191, 0.8)',
                  cursor: 'pointer',
                },
              },
            }}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
}

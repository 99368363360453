export const technologies = [
  {
    t: 'Frontend',
    bg: 'rgb(26, 204, 137, 255)',
    list: [
      {
        icon: './Icons/JS.png',
        level: 'Javascript',
        cl: 'rgb(26, 204, 137, 255)',
      },
      { icon: './Icons/TS.png', level: 'Typescript', cl: '#52c19c' },
      { icon: './Icons/React.png', level: 'React', cl: '#52c19c' },
      { icon: './Icons/Angular.png', level: 'Angular', cl: '#52c19c' },
    ],
  },
  {
    t: 'Backend',
    bg: '#e044a4',
    list: [
      { icon: './Icons/Node.png', level: 'Node', cl: '#e044a4' },
      { icon: './Icons/Nest.png', level: 'Nest', cl: '#e044a4' },
      { icon: './Icons/Mongo.png', level: 'Mongo', cl: '#e044a4' },
      { icon: './Icons/MySql.png', level: 'MySql', cl: '#e044a4' },
    ],
  },
  {
    t: 'Deployment & Testing',
    bg: '#6aecef',
    list: [
      { icon: './Icons/Docker.png', level: 'Docker', cl: '#6aecef' },
      { icon: './Icons/Jest.png', level: 'Jest', cl: '#6aecef' },
      { icon: './Icons/Git.png', level: 'Git', cl: '#6aecef' },
    ],
  },
];

export const text =
  '   Welcome to my website. Here, you can find information about me, the technologies I utilize, and the projects I have deployed thus far.';
export const text1 =
  '  I never take things too seriously.  Instead, I prioritize my family, friendships, and love for sports above all else.';

export const portfolioArray = [
  {
    title: 'Literats',
    description:
      'The app provides users with the ability to create and edit meetings, as well as to comment on them. Users can also sort and search for previously added meetings, as well as access a history and gallery section to sort and preview meetings.',
    technology: [
      'TypeScript',
      'React',
      'NestJS',
      'MySQL',
      'Backend under construction',
    ],
    link: 'https://literats.techcode.dev/',
    gitLink: 'random,',
  },
  {
    title: 'Collections',
    description:
      'The app allows users create and edit collections  as well as to comment on them and adding items. Night mode functionality available.',
    technology: ['React', 'Express', 's3bucket', 'MongoDB'],
    link: 'https://collections.techcode.dev',
    gitLink: 'random1,',
  },
  {
    title: 'Notes',
    description: 'Simple app to add and share notes with others.',
    technology: ['React', 'MongoDB'],
    link: 'https://notes.techcode.dev',
    gitLink: 'random,',
  },
  {
    title: 'Employee',
    description:
      'Layout in TS to manage employees. Mainly for drag-and-drop testing and utilization.',
    technology: ['Javascript', 'TypeScript'],
    link: 'https://employee.techcode.dev',
    gitLink: 'random,',
  },
  {
    title: 'BookApi',
    description:
      'This API enables users to create accounts and provides basic functionality for managing books from an external Google Books API library.',
    technology: ['Node.js', 'MVC', 'Express', 'MongoDB'],
    link: 'https://bookapi.techcode.dev/',
    gitLink: 'random1,',
  },
  {
    title: 'Shop',
    description:
      'My very first fullstack app :) This app allows users to create and edit items and customize their prices. Additionally, there is a functionality to create invoices in PDF.',
    technology: ['Express', 'MVC', 'MongoDB'],
    link: 'https://shop.techcode.dev/',
    gitLink: 'random1,',
  },
];

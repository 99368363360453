// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/images/roallercoaster.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App {\n  text-align: center;\n  color: #f2f2f2;\n  display: flex;\n  flex-direction: column;\n  background-color: #080605;\n  background-repeat: no-repeat;\n  background-position: top center;\n  background-size: contain;\n  height: 100vh;\n  width: 100%;\n  box-sizing: border-box;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  position: relative;\n}", "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,4BAAA;EACA,+BAAA;EACA,wBAAA;EACA,aAAA;EACA,WAAA;EACA,sBAAA;EACA,yDAAA;EACA,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,kBAAA;AACF","sourcesContent":[".App {\n  text-align: center;\n  color: #f2f2f2;\n  display: flex;\n  flex-direction: column;\n  background-color: #080605;\n  background-repeat: no-repeat;\n  background-position: top center;\n  background-size: contain;\n  height: 100vh;\n  width: 100%;\n  box-sizing: border-box;\n  background-image: url('../public/images/roallercoaster.png');\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

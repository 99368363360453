import React from 'react';
import './module.technologies.scss';
import { motion } from 'framer-motion';
import { technologies } from '../utils/helpers';

// unifikacja #15162e

export const Technologies = () => {
  return (
    <motion.div
      transition={{
        duration: 1,
        ease: 'easeOut',
        delay: 1,
      }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      initial={{ opacity: 0, display: 'none' }}
      animate={{ opacity: 1, display: 'flex' }}
      className="root-technologies"
    >
      <div className="technologies-wrapper">
        {technologies.map((item, index) => (
          <motion.div
            initial={{ y: -1000 }}
            animate={{ y: 0 }}
            transition={{
              delay: 1 + index * 0.2,
              duration: 0.8,
              type: 'twin',
              stiffness: 100,
              damping: 10,
            }}
            key={index}
            className="technologyDown"
          >
            <h1 style={{ color: item.bg }}>{item.t.toUpperCase()}</h1>

            <div className="icons-wrapper">
              {item.list?.map((item, i) => (
                <div key={i} className="single-icon-wrapper">
                  <img
                    className="logo"
                    src={item.icon}
                    alt={item.icon}
                    key={i}
                  />
                  <p style={{ color: item.cl }} className="text-logo">
                    {item.level.toUpperCase()}
                  </p>
                </div>
              ))}
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

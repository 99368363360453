import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
} from 'react-simple-maps';

const Map = () => {
  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      width={1000}
      height={1200}
      style={{ width: '100%', height: '100%' }}
      projectionConfig={{
        rotate: [-10.0, -52.0, 0],
        center: [0, 0],
        scale: 2100,
      }}
    >
      <Geographies
        geography="/features.json"
        fill="#ffffff84"
        stroke="#111111"
        strokeWidth={0.5}
      >
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
      <Annotation
        subject={[16.43, 52.17]}
        dx={-90}
        dy={-30}
        connectorProps={{
          stroke: '#ffffff',
          strokeWidth: 3,
          strokeLinecap: 'round',
        }}
      >
        <text
          x="-8"
          y="-10"
          fontFamily="Amatic SC"
          fontSize={60}
          textAnchor="end"
          alignmentBaseline="middle"
          fill="#ffffff"
        >
          {'Poznan'}
        </text>
      </Annotation>
    </ComposableMap>
  );
};

export default Map;

import React, { useRef, useState } from 'react';
import './module.contact.scss';
import Map from './Map';
import emailjs from '@emailjs/browser';
import { Snackbar } from '@mui/material';
import { motion } from 'framer-motion';

export const Contact = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const form = useRef<HTMLFormElement | null>(null);
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (form.current) {
      emailjs
        .sendForm(
          'service_lpg94t4',
          'template_hn8a52m',
          form.current,
          '1byX0Hq7OBew7sutH'
        )
        .then(
          (result) => {
            form.current?.reset();
            setSnackbarOpen(true);
            setSnackbarMessage(
              "I've got your message. Stay tuned for the response !"
            );
          },
          (error) => {
            setSnackbarOpen(true);
            setSnackbarMessage(
              ' Oops! Something went wrong. Please resubmit your form.'
            );
          }
        );
    }
  };

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, display: 'none' }}
      animate={{ opacity: 1, display: 'flex' }}
      transition={{
        delay: 1,
      }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className="root-contact"
    >
      <div className="container-contact">
        <div className="left-side-contact">
          <form ref={form} onSubmit={handleSubmit} className="contact-form">
            <h1 className="form-header">Contact Me</h1>
            <input
              className="contact-form-input"
              placeholder="Name"
              name="name"
              required
            />
            <input
              className="contact-form-input"
              placeholder="Email"
              name="email"
              required
            />
            <textarea
              className="contact-from-textarea"
              placeholder="Write your message"
              name="messge"
              required
              rows={8}
            />
            <button type="submit" className="contact-button">
              Send
            </button>
          </form>
        </div>
        <div className="right-side-contact">
          <Map />
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        message={snackbarMessage}
        sx={{
          '& .MuiSnackbar-root': {
            backgroundColor: '#52c19c',
          },
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      />
    </motion.div>
  );
};
